import { makeAutoObservable } from "mobx";
import apiv2 from "../../src/apiv2";
import React from "react";
import { useToast } from "../components/toast/useToast";
import moment from "moment";
import {
  READABLE_DATE_FORMAT,
  READABLE_TIME_FORMAT_MOMENT,
  SERVER_DATE_FORMAT,
  SERVER_TIME_FORMAT
} from "../utils/dates";
import { Tooltip, Link as MuiLink, IconButton } from "@mui/material";
import OpenIcon from '@mui/icons-material/OpenInNew';
import _ from "lodash";
import authStore from "./authStore";
import Binder from "../utils/binder";

const URL = process.env.EMS_API_URL || location.origin;
const toastManager = useToast();

const checkRequestForSuccess = (response, callback) => {
  if (response.mailing_id) {
    if (callback) {
      callback();
    }
    toastManager.showToast(
      <div>Сообщение пациенту отправилось успешно</div>,
      {
        duration: 10000
      }
    );
  } else {
    toastManager.showToast(
      <div>Произошла ошибка при отправке сообщения</div>,
      {
        duration: 10000
      }
    );
  }
};

const transformScheduleData = (data) => {
  return data.map((item) => {
    return {
      id: item.id,
      schedule_insert: { id: item.id },
      date: item.date,
      time: item.time,
      organization: {
        name: item.doctor_organization__organization__name
      },
      doctor_organization: {
        doctor: {
          last_name: item.doctor_organization__doctor__last_name,
          first_name: item.doctor_organization__doctor__first_name,
          middle_name: item.doctor_organization__doctor__middle_name,
          specialities: item.doctor_organization__doctor__specialities__name.map((speciality) => ({ name: speciality }))
        }
      },
      online_link_for_patient: item.online_link_for_patient,
      type: item.type
    }
  })
}

export const TEMPLATES = {
  /** Отмена приема */
  MESSAGE_INITIATION_CANCEL: "message_initiation_cancel",
  /** Перенос приема */
  MESSAGE_INITIATION_TRANSFER: "message_initiation_transfer",
  /** Открытие расписания (лист ожидания) */
  MESSAGE_INITIATION_OPENING_SCHEDULE: "message_initiation_opening_schedule",
  /** Освободившийся слот (лист ожидания) */
  MESSAGE_INITIATION_VACANT_SLOT: "message_initiation_vacant_slot",
  /** Подготовка к процедурам */
  MESSAGE_INITIATION_PREPARATION_PROCEDURES: "message_initiation_preparation_procedures",
  /** Динамическое сообщение */
  MESSAGE_INITIATION_DYNAMIC_TEXT: "message_initiation_dynamic_text_utility",
  /** Обратная связь */
  T_FEEDBACK_NEW: "t_feedback_new",
};

export const TEMPLATE_TEXTS = {
  [TEMPLATES.MESSAGE_INITIATION_CANCEL]:
    "Приветствуем вас 👋🏻\nЭто служба заботы сети «Клиника Фомина» \n\nК сожалению, мы вынуждены сообщить вам об отмене вашего приема 😔\nМы приносим извинения за возможные неудобства и будем рады предложить вам альтернативные даты и время для визита 🙌🏻\n\nПожалуйста, свяжитесь с нами,чтобы перенести ваш прием на удобное для вас время.",
  [TEMPLATES.MESSAGE_INITIATION_TRANSFER]:
    "Приветствуем вас 👋🏻\nЭто служба заботы сети «Клиника Фомина»\n\nМы хотели бы уведомить вас о необходимости переноса вашего приема 😔\n\nПожалуйста, свяжитесь с нами для выбора другого времени. \nБлагодарим за ваше понимание и терпение ❤️",
  [TEMPLATES.MESSAGE_INITIATION_OPENING_SCHEDULE]:
    "Приветствуем вас 👋🏻\nЭто служба заботы сети «Клиника Фомина»\n\nРады сообщить, что у нас открылось расписание для приема у специалиста 😍\nМы увидели, что вы в списке ожидания, и приглашаем записаться на прием в удобное для вас время!\n\nПожалуйста, свяжитесь с нами, чтобы выбрать подходящую дату и время. \nМы будем рады видеть вас в нашей клинике ❤️",
  [TEMPLATES.MESSAGE_INITIATION_VACANT_SLOT]:
    "Приветствуем вас 👋🏻\nЭто служба заботы сети «Клиника Фомина»\n\nРады сообщить, что у нас освободился слот для приема у специалиста 😍\nМы увидели, что вы в списке ожидания, и приглашаем записаться на прием! \n\nПожалуйста, свяжитесь с нами, чтобы выбрать подходящую дату и время. \nМы будем рады видеть вас в нашей клинике ❤️",
  [TEMPLATES.MESSAGE_INITIATION_PREPARATION_PROCEDURES]:
    "Приветствуем вас 👋🏻\nЭто служба заботы сети «Клиника Фомина». \n\nМы рады помочь вам и расскажем, как правильно подготовиться к процедуре.\n\nПознакомиться с подготовкой вы можете здесь: {{link}}\n\nЕсли возникнут вопросы, всегда рады помочь ❤️",
  [TEMPLATES.MESSAGE_INITIATION_DYNAMIC_TEXT]:
    "Приветствуем вас 👋🏻\nЭто служба заботы сети «Клиника Фомина». \n\n{{message_one}}\n\n{{message_two}}",
  [TEMPLATES.T_FEEDBACK_NEW]:
    "{{last_name}} {{first_name}}, здравствуйте 🙌🏻\nБлагодарим вас за то, что выбрали нашу клинику!\nЧтобы стать еще лучше для наших пациентов, мы хотим узнать ваше мнение о нашем сервисе и услугах ❤️\nБудем благодарны, если вы оставите отзыв: {{link}}\n\nВ благодарность за ваше мнение мы пополним вашу программу лояльности бонусными баллами в размере 500 рублей 😍"
};

export const LINK_TYPES = {
  MEDICAL_FILE: 'medical_file',
  PATIENT_GROUPS: 'patient_groups',
  PAYMENT: 'payment',
  APPOINTMENT_WEB: 'appointment_web',
  APPOINTMENT_DJANGO: 'appointment_django',
  SCHEDULE: 'SCHEDULE',
};

export const LINKS = {
  [LINK_TYPES.MEDICAL_FILE]: (params) => `${URL}/django/appointments/medicalfile/${params.id}/change/`,
  [LINK_TYPES.PATIENT_GROUPS]: (params) => `${URL}/django/appointments/patientgroup/${params.id}/change/`,
  [LINK_TYPES.PAYMENT]: (params) => `${URL}/django/payment/payment/${params.id}/change/`,
  [LINK_TYPES.APPOINTMENT_WEB]: (params) => `${URL}/appointment/${params.id}`,
  [LINK_TYPES.APPOINTMENT_DJANGO]: (params) => `${URL}/django/appointments/appointment/${params.id}/change/`,
  [LINK_TYPES.SCHEDULE]: (params) => `${URL}/django/appointments/scheduleinsert/${params.id}/change/`
};

export const paymentTableConfig = {
  columnOrder: [
    "mrt-row-actions",
    "fullNamePatient",
    "fullNameDoctor",
    "schedule__doctor_organization__organization__name",
    "service__name",
    "service_amount",
    "payment_status"
  ],
  additionalColumns: [
    {
      accessorKey: 'schedule__doctor_organization__organization__name',
      additionalFields: ["schedule", "schedule__date", "schedule__time"],
      header: 'Ячейка расписания',
      size: 550,
      accessorFn: (originalRow) => originalRow.schedule__doctor_organization__organization__name,
      filterFn: 'icontains',
      Cell: ({ row }) => {
        if (!row.original.schedule) {
          return "";
        }
        const organization = row.original.schedule__doctor_organization__organization__name || "";
        const date = row.original.schedule__date ? moment(row.original.schedule__date).format(READABLE_DATE_FORMAT) : "";
        const time = row.original.schedule__time ? moment(row.original.schedule__time, SERVER_TIME_FORMAT).format(READABLE_TIME_FORMAT_MOMENT) : "";

        return `${organization} ${date ? `- ${date}` : ""} ${time ? `, ${time}` : ""}`
      }
    }
  ],
  columnHidden: [
    "format",
    "schedule__date",
    "payment_provider__name",
    "payment_id",
    "created_at",
    "comment"
  ],
  renderRowActions: ({ row }) => {
    return (
      <Tooltip title="Перейти в Django" placement="right">
        <MuiLink
          underline="none"
          href={LINKS[LINK_TYPES.PAYMENT]({ id: row.original.id })}
          target="_blank"
          rel="noreferrer"
        >
          <IconButton>
            <OpenIcon />
          </IconButton>
          {/*Перейти*/}
        </MuiLink>
      </Tooltip>
    )
  }
};

class VoxInformationPatientStore {
  isInitialized = false;
  phone_number = null;
  dynamicTextOneRow = '';
  dynamicTextTwoRow = '';
  linkToInstructions = '';
  feedbackData = {};
  countCommunication = 5;
  countShowedScheduleItems = 3;
  is_vox = false;

  constructor() {
    this.resetState();
    Binder.bind(this, VoxInformationPatientStore);
    makeAutoObservable(this);
  }

  resetState() {
    this.medicalFiles = [];
    this.communications = [];
    this.scheduleFuture = [];
    this.schedulePast = [];
    /** Вкладка "Приемы" */
    this.patients = [];
    this.countCommunication = 5;
    this.countShowedScheduleItems = 3;
    this.appointmentsTableData = [];
    this.appointmentsTableDataInitialized = false;
  }

  setIsVox(value) {
    this.is_vox = value;
  }

  setPhoneNumber(value) {
    this.isInitialized = false;
    this.phone_number = value;
    this.resetState();
  }

  setDynamicTextOneRow(value) {
    this.dynamicTextOneRow = value;
  }

  setDynamicTextTwoRow(value) {
    this.dynamicTextTwoRow = value;
  }

  setCountCommunication(value) {
    this.countCommunication = value;
  }

  setCountShowedScheduleItems(value) {
    this.countShowedScheduleItems = value;
  }

  setLinkToInstructions(value) {
    this.linkToInstructions = value;
  }

  setFeedbackData(key, value) {
    this.feedbackData[key] = value;
  }

  async fetchCommunications() {
    try {
      const response = await apiv2.integrations.getCommunication(this.phone_number);
      this.communications = response.map((item) => {
        item.templateWithVariables = item.communication_mailing.template || '';
        Object.keys(item.message_data).forEach((key) => {
          if (item.templateWithVariables.includes(`{{${key}}}`)) {
            item.templateWithVariables = item.templateWithVariables.replaceAll(`{{${key}}}`, item.message_data[key]);
          }
        })
        return item;
      });
    } catch (e) {
      console.log(e);
    }
  }

  async fetchSchedule() {
    try {
      const currentTime = moment().format(SERVER_TIME_FORMAT);
      const currentDate = moment().format(SERVER_DATE_FORMAT);
      const response = await apiv2.schedule.scheduleListWithValues({
        filter_params: {
          patient_phone_number__icontains: this.phone_number
        },
        values_params: [
          "id",
          "datetime",
          "date",
          "time",
          "doctor_organization__organization__name",
          "doctor_organization__doctor__last_name",
          "doctor_organization__doctor__first_name",
          "doctor_organization__doctor__middle_name",
          "online_link_for_patient",
          "type",
          "doctor_organization__doctor__specialities__name"
        ],
        annotate_mtm_params: {
          "doctor_organization__doctor__specialities": "doctor_organization__doctor__specialities__name"
        },
        meta: { per_page: 1000000 }
      });
      const transformData = transformScheduleData(response.data);
      this.scheduleFuture = transformData
        .filter((item) => item.date === currentDate && item.time > currentTime || item.date > currentDate || item.date >= currentDate && !item.time);
      this.schedulePast = transformData
        .filter((item) => item.date === currentDate && item.time < currentTime || item.date < currentDate || item.date < currentDate && !item.time)
        .sort((a, b) => moment(b.date) - moment(a.date));
    } catch (e) {
      console.log(e);
    }
  }

  async fetchMedicalFiles() {
    try {
      const response = await apiv2.integrations.getMedicalFiles(this.phone_number);
      if (!response.length) {
        return;
      }

      this.medicalFiles = response.map((item) => {
        return {
          number: item.number,
          id: item.id,
          patient: item.patient,
          organization: item.organization,
          badge: item.badge,
          policies: item.patient.policies
        }
      });
      this.patients = _.uniqBy(this.medicalFiles.map(medicalFile => ({
        fullNamePatient: `${medicalFile.patient.last_name} ${medicalFile.patient.first_name} ${medicalFile.patient.middle_name}`,
        id: medicalFile.patient.id
      })), "id");
    } catch (e) {
      console.log(e);
    }
  }

  async initialize() {
    if (!this.phone_number) {
      console.log('Нету параметра phone_number');
      return;
    }
    await this.fetchCommunications();
    await this.fetchMedicalFiles();
    await this.fetchSchedule();
    this.isInitialized = true;
  }

  async sendMessageInitiationCancel() {
    const response = await apiv2.communication.sendDynamicMessage({
      phone_number: this.phone_number, template: TEMPLATES.MESSAGE_INITIATION_CANCEL
    });
    checkRequestForSuccess(response);
  }

  async sendMessageInitiationTransfer() {
    const response = await apiv2.communication.sendDynamicMessage({
      phone_number: this.phone_number, template: TEMPLATES.MESSAGE_INITIATION_TRANSFER
    });
    checkRequestForSuccess(response);
  }

  async sendMessageInitiationOpeningSchedule() {
    const response = await apiv2.communication.sendDynamicMessage({
      phone_number: this.phone_number, template: TEMPLATES.MESSAGE_INITIATION_OPENING_SCHEDULE
    });
    checkRequestForSuccess(response);
  }

  async sendMessageInitiationVacantSlot() {
    const response = await apiv2.communication.sendDynamicMessage({
      phone_number: this.phone_number, template: TEMPLATES.MESSAGE_INITIATION_VACANT_SLOT
    });
    checkRequestForSuccess(response);
  }
  async sendMessageInitiationPreparationProcedures() {
    const response = await apiv2.communication.sendDynamicMessage({
      messageData: { link: this.linkToInstructions }, phone_number: this.phone_number, template: TEMPLATES.MESSAGE_INITIATION_PREPARATION_PROCEDURES
    });
    checkRequestForSuccess(response, () => this.linkToInstructions = '');
  }

  async sendDynamicMessagePatient() {
    const response = await apiv2.communication.sendDynamicMessage({
      messageData: { message: { message_one: this.dynamicTextOneRow, message_two: this.dynamicTextTwoRow }}, phone_number: this.phone_number, template: TEMPLATES.MESSAGE_INITIATION_DYNAMIC_TEXT
    });
    checkRequestForSuccess(response, () => {
      this.dynamicTextOneRow = '';
      this.dynamicTextTwoRow = '';
    });
  }

  async sendFeedbackNew() {
    const response = await apiv2.communication.sendDynamicMessage({
      messageData: {
        last_name: this.feedbackData?.last_name || "",
        first_name: this.feedbackData?.first_name || "",
        link: this.feedbackData?.link || ""
      },
      phone_number: this.phone_number,
      template: TEMPLATES.T_FEEDBACK_NEW
    });
    checkRequestForSuccess(response, () => this.feedbackData = {});
  }

  async getAppointmentsByPatient() {
    const response = await apiv2.appointments.getAppointmentsByPatientId(this.patients[0].id, false);
    const appointments = response.map((item) => {
      return {
        appointmentId: item.data.id,
        fullNamePatient: this.patients[0].fullNamePatient,
        fullNameDoctor: `${item.data.doctor.last_name} ${item.data.doctor.first_name} ${item.data.doctor.middle_name}`,
        inProgress: item.data.in_progress,
        layout: item.data?.layout?.name,
        isCancelled: item.data.is_cancelled,
        date: item.data?.date || "",
        time: item.data?.time ? moment(item.data.time, SERVER_TIME_FORMAT).format(READABLE_TIME_FORMAT_MOMENT) : "",
      }
    });

    this.appointmentsTableData = [...this.appointmentsTableData, ...appointments];

    this.appointmentsTableDataInitialized = true;
  }
}

export default new VoxInformationPatientStore();
