import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class FullSchedule {
  apiService;
  constructor() {
    this.apiService = new ApiService('/api', null, null, API_URL);
  }

  getFullSchedule(params, needAbortPreviousRequest) {
    return this.apiService.get(`full-schedule`, params, {}, true, "", false, needAbortPreviousRequest);
  }
}

export default new FullSchedule();
