import ApiService from "../apiService";
const API_URL = process.env.EMS_API_URL || location.origin;

class VRTReports {
  apiService;
  constructor() {
    this.apiService = new ApiService("/api/reports/vrt-report", null, null, API_URL);
  }

  getReportPuncture({ filter_params, meta = {}, values_params, order_by_params }) {
    const interval = filter_params.interval;
    delete filter_params.interval;

    return this.apiService.post(
      `puncture`,
      {},
      {
        filter_params,
        current_page: meta.current_page,
        per_page: meta.per_page,
        interval,
        values_params,
        order_by_params
      },
      {},
      true,
      "",
      true
    );
  }

  getReportTransfer({ filter_params, meta = {}, values_params, order_by_params }) {
    const interval = filter_params.interval;
    delete filter_params.interval;

    return this.apiService.post(
      `transfer`,
      {},
      {
        filter_params,
        current_page: meta.current_page,
        per_page: meta.per_page,
        interval,
        values_params,
        order_by_params
      },
      {},
      true,
      "",
      true
    );
  }

  getReportProgramsWithSM({ filter_params, meta = {}, values_params, order_by_params }) {
    const interval = filter_params.interval;
    delete filter_params.interval;

    return this.apiService.post(
      `surogate-mothers`,
      {},
      {
        filter_params,
        current_page: meta.current_page,
        per_page: meta.per_page,
        interval,
        values_params,
        order_by_params
      },
      {},
      true,
      "",
      true
    );
  }

  getReportProgramsWithPGTStore({ filter_params, meta = {}, values_params, order_by_params }) {
    const interval = filter_params.interval;
    delete filter_params.interval;

    return this.apiService.post(
      `program-pgt`,
      {},
      {
        filter_params,
        current_page: meta.current_page,
        per_page: meta.per_page,
        interval,
        values_params,
        order_by_params
      },
      {},
      true,
      "",
      true
    );
  }

  getReportPregnancyOutcomesStore({ filter_params, meta = {}, values_params, order_by_params }) {
    const interval = filter_params.interval;
    delete filter_params.interval;

    return this.apiService.post(
      `pregnancy-outcome`,
      {},
      {
        filter_params,
        current_page: meta.current_page,
        per_page: meta.per_page,
        interval,
        values_params,
        order_by_params
      },
      {},
      true,
      "",
      true
    );
  }

  async getReportMariborConsensus(params) {
    return await this.apiService.post(`mariborian-concensus`, {}, params, {}, true, "", true);
  }

  async getReportOocyteDonationPrograms(params) {
    return await this.apiService.post(`program-oocyte-donors`, {}, params, {}, true, "", true);
  }

  async getReportProgramsWithDonorSperm(params) {
    return await this.apiService.post(`program-sperm-donor`, {}, params, {}, true, "", true);
  }
}

export default new VRTReports();
