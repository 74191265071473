import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class NewGeneralSchedule {
  apiService;
  constructor() {
    this.apiService = new ApiService("/api", null, null, API_URL);
  }

  getSchedule({
    start_date,
    end_date,
    organization_ids,
    doctor_ids,
    patient_id,
    service_ids,
    speciality_ids
  }) {
    return this.apiService.get(
      `new-schedule`,
      {
        start_date,
        end_date,
        organization_ids,
        doctor_ids,
        patient_id,
        service_ids,
        speciality_ids
      },
      {},
      true,
      "",
      false,
      true
    );
  }

  getAvailableOrganizations() {
    return this.apiService.get(`new-schedule/available-organizations`, {});
  }

  getServices({ name, organization_ids, doctor_ids, speciality_ids }) {
    return this.apiService.get(`new-schedule/services`, {
      name,
      organization_ids,
      doctor_ids,
      speciality_ids
    });
  }

  getSpecialities({ organization_ids }) {
    return this.apiService.get(`new-schedule/specialities`, {
      organization_ids
    });
  }

  getDoctors({ full_name, organization_ids, specialization_type, services_ids }) {
    return this.apiService.get(
      `new-schedule/doctors`,
      {
        full_name,
        organization_ids,
        specialization_type,
        services_ids
      },
      {},
      true,
      "",
      false,
      true
    );
  }

  getPatients({ name, organization_ids }) {
    return this.apiService.get(`new-schedule/patients`, {
      full_name: name,
      organization_ids
    });
  }
}

export default new NewGeneralSchedule();
