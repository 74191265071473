// @flow
import React, { Suspense, useCallback, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { lazily } from "react-lazily";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import useReactRouter from "use-react-router";
import "regenerator-runtime/runtime.js";

import { currentUser } from "./stores/auth";
import chatStore from "./stores/chatStore";
import noticesStore from "./stores/noticesStore";
import authStore from "./stores/authStore";
import headerStore, { TABS_URL } from "./stores/headerStore";
import ticketStore from "./stores/ticketStore";
import connectionStability from "./stores/connectionStability";
import reviewStore, { CLINIC_CODE } from "./stores/reviewStore";

import {
  FEATURE,
  useApplicationFeatureEnabled,
  useApplicationFeatures,
  useApplicationFeatureValue,
  useApplicationFeatureValueIdArray
} from "./utils/applicationFeatures";
import { setVoximplantSupportData } from "./utils/voximplantSupport";
import { useActionsLoggingTool } from "./utils/actionsLoggingTool";

import { usePatientReminders } from "./components/appointment-page/usePatientReminders";
import { newColorsRassvet } from "./components/styleguide/newColorsRassvet";
import { newColors } from "./components/styleguide/newColors";

import { PaymentPage } from "./components/payment/PaymentPage";
import { SupportPage } from "./components/support/SupportPage";

const { Toaster } = lazily(() => import("./components/toast/toast"));
const { ConnectionErrorModal } = lazily(() => import("./components/connection-error-modal/ConnectionErrorModal"));
const { VoximplantSupport } = lazily(() => import("./components/voximplant-support/VoximplantSupport"));
const { VoxInformationPatient } = lazily(() => import("./components/vox-information-patient/VoxInformationPatient"));
const { VoximplantSupportWebView } = lazily(() => import("./components/voximplant-support/VoximplantSupportWebView"));
const { ReviewPage } = lazily(() => import("./components/review-page/ReviewPage"));
const { ReviewStepTwo } = lazily(() => import("./components/review-page/ReviewStepTwo"));
const { ReviewStepThree } = lazily(() => import("./components/review-page/ReviewStepThree"));
const { ReviewStepThreeNegative } = lazily(() => import("./components/review-page/ReviewStepThreeNegative"));
const { ReviewStepFour } = lazily(() => import("./components/review-page/ReviewStepFour"));
const { ReviewStepFourNegative } = lazily(() => import("./components/review-page/ReviewStepFourNegative"));
const { ReviewStepFiveNegative } = lazily(() => import("./components/review-page/ReviewStepFiveNegative"));
const { ReviewStepSixWithCall } = lazily(() => import("./components/review-page/ReviewStepSixWithCall"));
const { ReviewStepSixNotCall } = lazily(() => import("./components/review-page/ReviewStepSixNotCall"));
const { ReviewAlready } = lazily(() => import("./components/review-page/ReviewAlready"));
const { MediaPermissionLockModal } = lazily(() => import("./components/common/mediaPermissionLockModal"));
const { MediaPermissionSelectModal } = lazily(() => import("./components/common/mediaPermissionSelectModal"));
const { MediaPermissionNotDeviceModal } = lazily(() => import("./components/common/mediaPermissionNotDeviceModal"));
const { VoximplantConnectionErrorModal } = lazily(() => import("./components/chats/modals/VoximplantConnectionErrorModal"));
const { LoginPage } = lazily(() => import("./components/login-page/LoginPage"));
const { SurveyCorporatePartyPage } = lazily(() => import("./components/survey-page/SurveyCorporatePartyPage"));
const { VideoCall } = lazily(() => import("./components/chats/video/VideoCall"));
const { TicketPage } = lazily(() => import("./components/ticket-page/TicketPage"));
const { TicketCreatePage } = lazily(() => import("./components/ticket-page/TicketCreatePage"));
const { TicketEditPage } = lazily(() => import("./components/ticket-page/TicketEditPage"));
const { SurveyPage } = lazily(() => import("./components/survey-page/SurveyPage"));
const { CorporatePortal } = lazily(() => import("./corporate-portal/CorporatePortal"));
const { ProfilePageTelemed } = lazily(() => import("./components/profile-page/ProfilePageTelemed"));
const { CheckupsPage } = lazily(() => import("./components/checkups-page/CheckupsPage"));
const { CheckupsPageThanks } = lazily(() => import("./components/checkups-page/CheckupsPageThanks"));
const { CheckupsPageAppointments } = lazily(() => import("./components/checkups-page/CheckupsPageAppointments"));
const { ReviewStatisticsPage } = lazily(() => import("./components/review-statistics-page/ReviewStatisticsPage"));
const { ReviewStatisticsEditPage } = lazily(() => import("./components/review-statistics-page/ReviewStatisticsEditPage"));
const { SurveyCorporatePortalEditPage } = lazily(() => import("./components/survey-page/SurveyCorporatePortalEditPage"));
const { SurveyFeedbackClinicEditPage } = lazily(() => import("./components/survey-page/SurveyFeedbackClinicEditPage"));
const { PaymentsStatisticsPage } = lazily(() => import("./components/payment-statistics-page/PaymentsStatisticsPage"));
const { VoxPatientConsent } = lazily(() => import("./components/vox-patient-consent/VoxPatientConsent"));
const { DashboardPage } = lazily(() => import("./components/dashboard-page/DashboardPage"));
const { VRTReportsPage } = lazily(() => import("./components/vrt-reports-page/VRTReportsPage"));
const { SurveyResultPage } = lazily(() => import("./components/survey-page/SurveyResultPage"));
const { SurveyEmployeeEditPage } = lazily(() => import("./components/survey-page/SurveyEmployeeEditPage"));
const { GeneralSchedulePageNew } = lazily(() => import("./components/general-schedule-page/GeneralSchedulePageNew"));
const { GeneralScheduleCalendarPage } = lazily(() => import("./components/general-schedule-page/calendar/GeneralScheduleCalendarPage"));
const { ScheduleSwitchPage } = lazily(() => import("./components/schedule-switch-page/ScheduleSwitchPage"));
const { ProfilePage } = lazily(() => import("./components/profile-page/ProfilePage"));
const { DoctorSalaryPage } = lazily(() => import("./components/doctor-salary-page/DoctorSalaryPage"));
const { BonusSchema } = lazily(() => import("./components/doctor-salary-page/bonus-schema/BonusSchema"));
const { ProfileTicketPage } = lazily(() => import("./components/ticket-page/ProfileTicketPage"));
const { DoctorPopularLayoutsPage } = lazily(() => import("./components/doctor-popular-layouts-page/DoctorPopularLayoutsPage"));
const { PrescribedServicesPage } = lazily(() => import("./components/prescribed-services-page/PrescribedServicesPage"));
const { FavoritesAppointmentPage } = lazily(() => import("./components/favorites-appointment-page/FavoritesAppointmentPage"));
const { NoticesPage } = lazily(() => import("./components/notices-page/NoticesPage"));
const { PatientsPage } = lazily(() => import("./components/patients-page/PatientsPage"));
const { AccountPage } = lazily(() => import("./components/account-page/AccountPage"));
const { PrintableAppointmentPage } = lazily(() => import("./components/appointment-print/PrintableAppointmentPage"));
const { FolderPrintPage } = lazily(() => import("./components/folder-page/FolderPrintPage"));
const { FolderAnalysesPrintPage } = lazily(() => import("./components/folder-page/FolderAnalysesPrintPage"));
const { AgreementPrintPage } = lazily(() => import("./components/folder-page/AgreementPrintPage"));
const { AgreementNewPrintPage } = lazily(() => import("./components/appointment-page/AgreementNewPrintPage"));
const { PrintableHistoryTablePage } = lazily(() => import("./components/history-table/PrintableHistoryTable"));
const { PrintablePrescriptionTablePage } = lazily(() => import("./components/prescription-table/PrintablePrescriptionTable"));
const { PrintableTablePage } = lazily(() => import("./components/table/PrintableTable"));
const { PrintableEmbryosDocumentPage } = lazily(() => import("./components/embryos-table/PrintableEmbryosDocumentPage"));
const { PrintableEmbryosPage } = lazily(() => import("./components/embryos-table/PrintableEmbryosPage"));
const { AppointmentsSwitchPage } = lazily(() => import("./components/appointment-switch-page/AppointmentsSwitchPage"));
const { GeneralSchedulePage } = lazily(() => import("./components/general-schedule-page/GeneralSchedulePage"));
const { ChatsPage } = lazily(() => import("./components/chats/ChatsPage"));
const { MyPatientsPage } = lazily(() => import("./components/my-patients/MyPatientsPage"));
const { MoreNavigationTabs } = lazily(() => import("./components/moreNavigationTabs/MoreNavigationTabs"));
const { ImageViewerNew } = lazily(() => import("./components/image-viewer/ImageViewerNew"));
const { PrintEditor } = lazily(() => import("./components/print-editor/PrintEditor"));

const ADDITIONAL_ROUTES = [
  "vox-support",
  "vox-information-patient",
  "support",
  "review",
  "finish-payment",
  "payment",
  "videocall",
  "corporate-portal",
  "corporate-survey",
  "review-statistics",
  "quest",
  "survey",
  "corporate-portal-statistics",
  "feedback-clinic-statistics",
  "survey-result",
  "tickets",
  "payments",
  "vox-patient-consent",
  "dashboard",
  "reports-vrt",
  "general-schedule-new"
];

const App = withRouter(
  observer(props => {
    const { history } = useReactRouter();
    const paths = location.pathname.split("/");
    const user = currentUser.getState();

    const isChatsEnabled = useApplicationFeatureEnabled(FEATURE.CHAT);
    const showProfile = useApplicationFeatureEnabled(FEATURE.PROFILE);
    const showPatientsPage = useApplicationFeatureEnabled(FEATURE.PATIENTS);
    const questionnaireUserId = useApplicationFeatureValue(FEATURE.HC_QUESTIONNAIRE_USER_ID);
    const SHOW_BONUS_SCHEMA = useApplicationFeatureEnabled(FEATURE.BONUS_SCHEMA);
    const serviceWorkersIDs = useApplicationFeatureValueIdArray(FEATURE.SERVICE_STAFF);
    const serviceAdminsIDs = useApplicationFeatureValueIdArray(FEATURE.SERVICE_ADMIN);
    const TICKET_DOCTOR_ASSIGNEE_MEDICAL_ERROR = useApplicationFeatureValueIdArray(FEATURE.TICKET_DOCTOR_ASSIGNEE_MEDICAL_ERROR);
    const generalScheduleNewEnabled = useApplicationFeatureEnabled(FEATURE.GENERAL_SCHEDULE_NEW);
    const serviceUsersIDs = Array.from(new Set([...(serviceWorkersIDs || []), ...(serviceAdminsIDs || [])]));

    useEffect(() => {
      if (isChatsEnabled && user && !ADDITIONAL_ROUTES.includes(paths[1])) {
        chatStore.initWS();
        chatStore.loadChats();
        chatStore.updateUnreadChatsCount();
      }
    }, [isChatsEnabled, user, history.location.pathname]);

    useEffect(() => {
      if (user) {
        noticesStore.initWS();
      }
    }, [user]);

    const handleMessageListener = useCallback(message => {
      if (message?.data === "eventGoBack") {
        props.history.push(TABS_URL[headerStore.lastMainNavigationTab]);
      }

      if (message?.data?.type === "createMobileTicket") {
        ticketStore.setMobileTicketVoxData(message.data.payload);
      }
    }, []);

    useEffect(() => {
      window.addEventListener("message", handleMessageListener);
      return () => window.removeEventListener("message", handleMessageListener);
    }, []);

    useEffect(() => {
      const unlisten = props.history.listen(location => {
        const voximplantData = { page: location.href };
        setVoximplantSupportData({
          client_email: voximplantData,
          ...window.VoxKitWidgetSettings.client_data
        });
      });
      return () => {
        unlisten();
      };
    }, []);

    /** Для WebView {7413} */
    if (paths[1] === "vox-support") {
      document.documentElement.style.background = "#fafafa";

      return (
        <Suspense>
          <VoximplantSupportWebView />
        </Suspense>
      );
    }

    useApplicationFeatures();
    usePatientReminders();
    useActionsLoggingTool();

    if (paths[1] === "vox-information-patient") {
      document.documentElement.style.background = "#fafafa";
      return (
        <Suspense>
          <Toaster />
          <VoxInformationPatient />
          <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
        </Suspense>
      );
    }

    if (!document.documentElement.style.getPropertyValue("--vh")) {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
      window.addEventListener("resize", () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      });
    }

    if (paths.length === 2 && paths[1] === "support") {
      document.documentElement.style.background = "transparent";
      return <SupportPage />;
    }

    if (paths[1] === "review") {
      if (
        reviewStore.clinic_code === CLINIC_CODE.rassvet ||
        location.search.includes("clinic=rassvet")
      ) {
        reviewStore.setClinicCode(CLINIC_CODE.rassvet);
        document.documentElement.style.background = newColorsRassvet.backgroundPrimary;
      } else {
        reviewStore.setClinicCode(CLINIC_CODE.fomina);
        document.documentElement.style.background = newColors.backgroundPrimary;
      }
      return (
        <Suspense>
          <Switch>
            <Route
              exact path="/review/:token"
              component={ReviewPage}
            />
            <Route
              path="/review/:token/step-two"
              component={ReviewStepTwo}
            />
            <Route
              path="/review/:token/step-three"
              component={ReviewStepThree}
            />
            <Route
              path="/review/:token/step-three-negative"
              component={ReviewStepThreeNegative}
            />
            <Route
              path="/review/:token/step-four"
              component={ReviewStepFour}
            />
            <Route
              path="/review/:token/step-four-negative"
              component={ReviewStepFourNegative}
            />
            <Route
              path="/review/:token/step-five-negative"
              component={ReviewStepFiveNegative}
            />
            <Route
              path="/review/:token/step-six-with-call"
              component={ReviewStepSixWithCall}
            />
            <Route
              path="/review/:token/step-six-not-call"
              component={ReviewStepSixNotCall}
            />
            <Route
              path="/review/:token/already"
              component={ReviewAlready}
            />
          </Switch>
        </Suspense>
      );
    }

    if (authStore.token && !authStore.user) {
      return null;
    }

    const registerServiceWorker = () => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.register(new URL("sw.js", import.meta.url), {
          scope: "/",
          type: "module"
        });
      }
    };

    registerServiceWorker();

    if (paths.length === 2 && paths[1] === "finish-payment") {
      document.documentElement.style.background = "transparent";
      try {
        window.ReactNativeWebView.postMessage(JSON.stringify({ status: "finished" }));
        // eslint-disable-next-line no-empty
      } catch (e) {}
      return (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "10px",
            width: "80vw"
          }}>
          <div style={{ textAlign: "center", paddingBottom: "20px", fontSize: "120%" }}>
            Оплата завершена
          </div>
          <div style={{ textAlign: "center" }}>Для продолжения вернитесь в приложение</div>
        </div>
      );
    }

    if (paths.length === 3 && paths[1] === "payment") {
      document.documentElement.style.background = "transparent";
      return <PaymentPage id={paths[2]} />;
    }

    if (paths.length >= 3 && paths[1] === "videocall") {
      chatStore.setCallId(paths[2]);

      if (!chatStore.currentChatId) {
        return null;
      }
      return (
        <Suspense>
          <Toaster />
          <MediaPermissionLockModal isShown={chatStore.showMediaPermissionLockModal} />
          <MediaPermissionSelectModal isShown={chatStore.showMediaPermissionSelectModal} />
          <MediaPermissionNotDeviceModal isShown={chatStore.showMediaPermissionNotDeviceModal} />
          <VoximplantConnectionErrorModal isShown={chatStore.showVoximplantConnectionErrorModal} />
          <VideoCall />
          <VoximplantSupport />
        </Suspense>
      );
    }

    if (location.pathname === "/tickets/segment/medical-error/create") {
      document.documentElement.style.background = "#fafafa";
      return (
        <Suspense>
          <Toaster />
          <Switch>
            <Route
              exact
              path="/tickets/segment/medical-error/create"
              component={TicketCreatePage}
            />
          </Switch>
          <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
        </Suspense>
      );
    }

    if (location.pathname.includes("/survey/")) {
      document.documentElement.style.background = "#F9F4F4";
      return (
        <Suspense>
          <Toaster />
          <Switch>
            <Route
              exact
              path="/survey/:id"
              component={SurveyPage}
            />
            <Route
              exact
              path="/survey/result/:id"
              component={SurveyPage}
            />
          </Switch>
          <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
        </Suspense>
      );
    }

    if (paths[1] === "corporate-portal" || user?.is_corporate_portal) {
      if (paths[1] !== "corporate-portal") {
        history.push("/corporate-portal");
      }
      return (
        <Suspense>
          <Toaster />
          <Switch>
            <Route
              path="/corporate-portal"
              component={CorporatePortal}
            />
          </Switch>
          <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
        </Suspense>
      );
    }

    if (!authStore.token || !authStore.user) {
      return (
        <Suspense>
          <Toaster />
          <LoginPage />
          <VoximplantSupport showMobile={true} />
          <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
        </Suspense>
      );
    }

    if (paths[1] === "corporate-survey") {
      document.documentElement.style.background = "#fafafa";
      return (
        <Suspense>
          <Toaster />
          <SurveyCorporatePartyPage />
          <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
        </Suspense>
      );
    }

    if (
      location.pathname.includes("/tickets/segment/medical-error") &&
      TICKET_DOCTOR_ASSIGNEE_MEDICAL_ERROR &&
      TICKET_DOCTOR_ASSIGNEE_MEDICAL_ERROR.includes(authStore.user.doctor.id)
    ) {
      document.documentElement.style.background = "#fafafa";
      return (
        <Suspense>
          <Toaster />
          <Switch>
            <Route
              exact
              path="/tickets/segment/medical-error"
              component={TicketPage}
            />
            <Route
              exact
              path="/tickets/segment/medical-error/create"
              component={TicketCreatePage}
            />
            <Route
              exact
              path="/tickets/segment/medical-error/:id"
              component={TicketEditPage}
            />
          </Switch>
          <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
        </Suspense>
      );
    }

    if (!authStore.user.doctor?.last_name && process.env.APP_FLAVOR === "telemed") {
      return (
        <Suspense>
          <Toaster />
          <ProfilePageTelemed afterRegistration={true} />
          <VoximplantSupport />
          <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
        </Suspense>
      );
    }

    if (authStore.user?.id === questionnaireUserId) {
      window.QUEST_MODE = true;
      return (
        <Suspense>
          <Switch>
            <Route
              exact
              path="/quest"
              component={CheckupsPage}
            />
            <Route
              exact
              path="/quest/thanks"
              component={CheckupsPageThanks}
            />
            <Route
              exact
              path="/quest/:appointment_id"
              component={CheckupsPageAppointments}
            />
          </Switch>
          <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
        </Suspense>
      );
    }

    if (
      paths[1] === "review-statistics" ||
      paths[1] === "corporate-portal-statistics" ||
      paths[1] === "feedback-clinic-statistics"
    ) {
      document.documentElement.style.background = "#fafafa";
      return (
        <Suspense>
          <Toaster />
          <Switch>
            <Route
              exact
              path="/review-statistics"
              component={ReviewStatisticsPage}
            />
            <Route
              exact
              path="/review-statistics/:id"
              component={ReviewStatisticsEditPage}
            />
            <Route
              exact
              path="/corporate-portal-statistics/:id"
              component={SurveyCorporatePortalEditPage}
            />
            <Route
              exact
              path="/feedback-clinic-statistics/:id"
              component={SurveyFeedbackClinicEditPage}
            />
          </Switch>
          <VoximplantSupport />
          <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
        </Suspense>
      );
    }

    if (paths[1] === "survey-result") {
      document.documentElement.style.background = "#fafafa";
      return (
        <Suspense>
          <Toaster />
          <Switch>
            <Route
              exact
              path="/survey-result"
              component={SurveyResultPage}
            />
            <Route
              exact
              path="/survey-result/:id"
              component={SurveyEmployeeEditPage}
            />
          </Switch>
          <VoximplantSupport />
          <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
        </Suspense>
      );
    }

    if (paths[1] === "tickets") {
      document.documentElement.style.background = "#fafafa";
      return (
        <Suspense>
          <Switch>
            <Route
              exact
              path="/tickets"
              component={TicketPage}
            />
            <Route
              exact
              path="/tickets/create"
              component={TicketCreatePage}
            />
            <Route
              exact
              path="/tickets/:id"
              component={TicketEditPage}
            />
            <Route
              exact
              path="/tickets/segment/profile"
              component={TicketPage}
            />
            <Route
              exact
              path="/tickets/segment/profile/create"
              component={TicketCreatePage}
            />
            <Route
              exact
              path="/tickets/segment/profile/:id"
              component={TicketEditPage}
            />
            <Route
              exact
              path="/tickets/segment/legal-lab"
              component={TicketPage}
            />
            <Route
              exact
              path="/tickets/segment/legal-lab/create"
              component={TicketCreatePage}
            />
            <Route
              exact
              path="/tickets/segment/legal-lab/:id"
              component={TicketEditPage}
            />
            <Route
              exact
              path="/tickets/segment/manager-dms"
              component={TicketPage}
            />
            <Route
              exact
              path="/tickets/segment/manager-dms/create"
              component={TicketCreatePage}
            />
            <Route
              exact
              path="/tickets/segment/manager-dms/:id"
              component={TicketEditPage}
            />
            <Route
              exact
              path="/tickets/segment/common"
              component={TicketPage}
            />
            <Route
              exact
              path="/tickets/segment/common/create"
              component={TicketCreatePage}
            />
            <Route
              exact
              path="/tickets/segment/common/:id"
              component={TicketEditPage}
            />
          </Switch>
          <VoximplantSupport />
          <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
        </Suspense>
      );
    }

    if (paths[1] === "payments") {
      document.documentElement.style.background = "#fafafa";
      return (
        <Suspense>
          <Switch>
            <Route
              exact
              path="/payments"
              component={PaymentsStatisticsPage}
            />
          </Switch>
          <VoximplantSupport />
          <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
        </Suspense>
      );
    }

    if (paths[1] === "vox-patient-consent") {
      document.documentElement.style.background = "#fafafa";
      return (
        <Suspense>
          <Switch>
            <Route
              exact
              path="/vox-patient-consent"
              component={VoxPatientConsent}
            />
          </Switch>
          <VoximplantSupport />
          <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
        </Suspense>
      );
    }

    if (paths[1] === "dashboard") {
      document.documentElement.style.background = "#fafafa";
      return (
        <Suspense>
          <Switch>
            <Route
              exact
              path="/dashboard"
              component={DashboardPage}
            />
          </Switch>
          <VoximplantSupport />
          <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
        </Suspense>
      );
    }

    if (paths[1] === "reports-vrt") {
      document.documentElement.style.background = "#fafafa";
      return (
        <Suspense>
          <Switch>
            <Route
              exact
              path="/reports-vrt"
              component={VRTReportsPage}
            />
          </Switch>
          <VoximplantSupport />
          <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
        </Suspense>
      );
    }

    if (generalScheduleNewEnabled && paths[1] === "general-schedule-new") {
      return (
        <Suspense>
          <Switch>
            <Route
              exact
              path="/general-schedule-new"
              component={GeneralSchedulePageNew}
            />
            <Route
              exact
              path="/general-schedule-new/calendar"
              component={GeneralScheduleCalendarPage}
            />
          </Switch>
          {location.pathname !== "/general-schedule-new/calendar" && <VoximplantSupport />}
          <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
        </Suspense>
      );
    }

    return (
      <Suspense>
        <Toaster />
        {/* Отключаем модалку с текстом о запуске блока ВРТ, потому что ссылка ведет на нерабочий Ноушен */}
        {/*<UpdateNewsModal />*/}
        <Switch>
          {serviceWorkersIDs &&
            (serviceUsersIDs.includes(user.id) ? (
              <Redirect exact from="/" to="/review-statistics" />
            ) : (
              <Redirect exact from="/" to="/schedule" />
            ))}
          <Route path="/schedule" component={ScheduleSwitchPage} />
          {/*<Route exact path="/throw-error" component={ThrowErrorPage} />*/}
          {showProfile && <Route exact path="/profile" component={ProfilePage} />}
          {showProfile && <Route exact path="/profile/salary" component={DoctorSalaryPage} />}
          {(showProfile && SHOW_BONUS_SCHEMA) && <Route exact path="/profile/progress" component={BonusSchema} />}
          {showProfile && <Route exact path="/profile/tickets" component={ProfileTicketPage} />}
          {showProfile && <Route exact path="/profile/tickets/create" component={TicketCreatePage} />}
          {showProfile && <Route path="/profile/tickets/:id" component={TicketEditPage} />}
          {showProfile && <Route exact path="/profile/popular-layouts" component={DoctorPopularLayoutsPage} />}
          <Route exact path="/prescribed-services-report" component={PrescribedServicesPage} />
          {showProfile && (
            <Route
              exact
              path="/profile/favorites-appointment"
              component={FavoritesAppointmentPage}
            />
          )}
          {showProfile && <Route exact path="/profile/notifications" component={NoticesPage} />}
          {showPatientsPage && <Route exact path="/patients" component={PatientsPage} />}
          {process.env.APP_FLAVOR === "telemed" && (
            <Route path="/profile" component={ProfilePageTelemed} />
          )}
          {process.env.APP_FLAVOR === "telemed" && (
            <Route path="/account" component={AccountPage} />
          )}
          <Route
            exact
            path="/appointment/:appointment_id/print/:for"
            component={PrintableAppointmentPage}
          />
          <Route
            exact
            path="/appointment/folder/:id/print/:concrete_folder?"
            component={FolderPrintPage}
          />
          <Route
            exact
            path="/appointment/folder/:id/analyzes/print/:type"
            component={FolderAnalysesPrintPage}
          />
          <Route
            exact
            path="/appointment/folder/:id/agreements/print/:agreementId"
            component={AgreementPrintPage}
          />
          <Route
            exact
            path="/appointment/:id/agreements/print/:agreementId"
            component={AgreementNewPrintPage}
          />
          <Route
            exact
            path="/appointment/:appointment_id/:section_id/print/history"
            component={PrintableHistoryTablePage}
          />
          <Route
            exact
            path="/appointment/:appointment_id/:section_id/print/prescriptions"
            component={PrintablePrescriptionTablePage}
          />
          <Route
            exact
            path="/appointment/:appointment_id/:section_id/print/table"
            component={PrintableTablePage}
          />
          <Route
            exact
            path="/embryos/:folder_id/:medical_file_id/print/documents/:document"
            component={PrintableEmbryosDocumentPage}
          />
          <Route
            exact
            path="/embryos/:folder_id/:medical_file_id/print/:filter"
            component={PrintableEmbryosPage}
          />
          <Route
            exact
            path="/embryos/:medical_file_id/print/documents/:document"
            component={PrintableEmbryosDocumentPage}
          />
          <Route
            exact
            path="/embryos/:medical_file_id/print/:filter"
            component={PrintableEmbryosPage}
          />

          <Route path="/appointment" component={AppointmentsSwitchPage} />
          <Route path="/general-schedule" component={GeneralSchedulePage} />
          <Route path="/chats/:id" component={ChatsPage} />
          <Route path="/chats" component={ChatsPage} />
          <Route exact path="/my-patients" component={MyPatientsPage} />
          {/** Раздел "Еще" для мобильных устройств */}
          <Route exact path="/more-navigation-tabs" component={MoreNavigationTabs} />
        </Switch>
        <VideoCall />
        {/** В случае срочного отката вернуть прошлую версию ImageViewer */}
        {/*<ImageViewer />*/}
        <ImageViewerNew />
        <PrintEditor />
        <VoximplantSupport />
        <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
      </Suspense>
    );
  })
);

export default App;
